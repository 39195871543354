@import '../../../../scss/theme-bootstrap';

.site-banner {
  text-align: $ldirection;
  color: $white;
  background-color: $color-off-black;
  height: $site-banner-height;
  line-height: normal;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  .icon {
    display: none;
    @include breakpoint($portrait-up) {
      position: absolute;
      top: 10px;
      margin-top: 5px;
      #{$ldirection}: 20px;
      color: $color-light-gray;
      width: 16px;
      height: 16px;
      display: block;
      cursor: pointer;
      @if $cr20 == true {
        display: none;
      }
    }
  }
  &__copy {
    font-size: 12px;
    line-height: get-line-height(13px, 20px);
    position: relative;
    padding: 0 13px 7px;
    font-weight: bold;
    font-family: $primary-font;
    font-weight: 500;
    letter-spacing: 1px;
    @include breakpoint($portrait-up) {
      @include swap_direction(padding, 0 20px 9px 42px);
      max-width: $max-width-large;
      margin: 0 auto;
    }
    p {
      margin-bottom: 0;
      line-height: inherit;
      @include breakpoint($portrait-up) {
        display: inline-block;
      }
      // The editor entered link
      a {
        font-size: 12px;
        font-family: $primary-font;
        font-weight: 500;
        letter-spacing: 1px;
        color: $white;
        @if $hit_area_update == true {
          min-height: 24px;
          display: inline-block;
        }
        @include breakpoint($portrait-up) {
          @if $hit_area_update == true {
            min-height: unset;
            display: unset;
          }
        }
      }
    }
    a {
      font-size: 12px;
      font-family: $primary-font;
      font-weight: 500;
      letter-spacing: 1px;
      color: $white;
      @if $hit_area_update == true {
        min-height: 24px;
        display: inline-block;
      }
      @include breakpoint($portrait-up) {
        @if $hit_area_update == true {
          min-height: unset;
          display: unset;
        }
      }
      &:hover,
      &:active {
        color: $color-link-hover;
      }
    }
    .site-banner-rotator-items {
      float: $ldirection;
      height: 25px;
      margin-#{$rdirection}: 45px;
      margin-top: 4px;
      overflow: hidden;
      @include breakpoint($small-down) {
        margin-#{$rdirection}: 0;
        width: 100%;
      }
      @include breakpoint($portrait-up) {
        @if $cr20 == true {
          text-align: center;
          float: none;
        }
      }
      div {
        height: 25px;
        color: $white;
      }
    }
  }
  &__close {
    display: none;
    @include breakpoint($portrait-up) {
      display: inline-block;
    }
  }
  &__professionals {
    display: block;
    @include breakpoint($portrait-up) {
      margin-#{$ldirection}: -20px;
      display: inline-block;
    }
    @include breakpoint($small-down) {
      display: none;
    }
    .icon {
      @include breakpoint($portrait-up) {
        margin-#{$rdirection}: 6px;
        position: static;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
  &__offer {
    display: none;
    background: $white;
    padding: 20px;
    position: absolute; // Trick to get fixed element relative to container
    width: 100%;
    z-index: 10;
    color: $white;
    &-close {
      position: absolute;
      top: 20px;
      #{$rdirection}: 45px;
      z-index: 20;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    &-content {
      width: 100%;
    }

    // Offer touts have a border styling on top
    // Last one should have one on the bottom here
    .basic-grid__item:last-child {
      border-bottom: 1px solid $color-light-gray;
    }
  }
  &__more {
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: $white;
  }
}
